import React from "react";
import structure from "../../asset/ZAECA_STRUCTURE.jpg";

export const OrgStructure = () => {
  return (
    <div className="space">
      <img src={structure} alt="" width={"100%"} srcset="" />
    </div>
  );
};
