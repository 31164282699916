import React from "react";
import { OrgStructure as OrgStructureComp } from "../component/about";
import { Container } from "react-bootstrap";
import { Menu } from "../component";
import { Helmet } from "react-helmet-async";

export const OrgStructure = () => {
    return (
      <>
        <Container
          fluid={true}
          className=""
          style={{
            padding: "0px",
            // paddingBottom: "50px",
            // background: "#eff8fc",
          }}
        >
          <Helmet>
            <title>About Us</title>
          </Helmet>
          <Menu />
          <Container>
            <OrgStructureComp />
          </Container>
          {/* <Footer/> */}
        </Container>
      </>
    );
}


