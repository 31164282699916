import axios from "axios";
import { baseURL } from "./baseUrl";

export function addNews(data, headers) {
  return axios.post(`${baseURL}/api/news`, data, headers);
}

export function publicNews(offset) {
  return axios.get(`${baseURL}/api/public/news`);
  // return axios.get(`http://127.0.0.1:8000/api/public/news`);
  // ${baseURL}/api/public/news
}

export function publicNewsAll(offset) {
  return axios.get(`${baseURL}/api/public/news/all?page=${offset}`);
}

export function loadNews(offset, headers) {
  return axios.get(`${baseURL}/api/news?page=${offset}`, headers);
}

export function publishNews(id, data, headers) {
  return axios.post(`${baseURL}/api/publish/${id}/news`, data, headers);
}

export function hotNewsList(headers) {
  return axios.get(`${baseURL}/api/hot/news/list`, headers);
}

export function hotNews(id, data, headers) {
  return axios.post(`${baseURL}/api/hot/${id}/news`, data, headers);
}

export function deleteNews(id, headers) {
  return axios.delete(`${baseURL}/api/delete/${id}/news`, headers);
}
