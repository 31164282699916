import React from "react";
import { Helmet } from "react-helmet-async";
import { RedioHome } from "../component/redio/home";

export const Redio = () => {
  const headers = {
    headers: {
      "Content-`Type": "application/json",
      //   Authorization: "Bearer" + auth?.access_token,
    },
  };

  return (
    <>
      <Helmet>
        <title>Redio Program</title>
      </Helmet>
      <div className="container-fluid px-4">
        <h1 className="mt-4 text-dark">Redio</h1>
        <RedioHome />
      </div>
    </>
  );
};
