import React, { useEffect, useState, useContext } from "react";
import { DateFormat } from "../../component/utility/dateFormat";
import { Pagination } from "../../component/utility";
import { ViewComplain } from "./viewComplain";
import { isRead } from "../../action/Complain";
import { BsCheck2Circle, BsXCircle, BsFillFileEarmarkTextFill } from "react-icons/bs";
import AuthContext from "../../contex/AuthContext";

export const HomeTable = (props) => {
  const { setOffset, offset, data, index, setIndex } = props;
  const [limit, setLimit] = useState(1);
  const [show, setShow] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [resp, seResp] = useState(null);
  const { auth } = useContext(AuthContext);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + limit);
  };

  const headers = {
    headers: {
      "Content-`Type": "application/json",
      Authorization: "Bearer" + auth?.access_token,
    },
  };

  const handleShow = (data) => {
    setViewData(data);
    const ids = data.id;

    if (data.isRead === 0) {
      isRead(ids, data, headers).then((resp) => {
        //   setData(resp.data.data);
        setIndex(index + 1);
        console.log(resp);
        // setPageCount(Math.ceil(resp.data.count / limit));
      });
    }
    console.log(data);
    setShow(true);
  };

  return (
    <>
      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Complain table
        </div>
        <div className="card-body table">
          <table
            className="table table-responsive table-striped table-hover"
            id="datatablesSimple"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th style={{ whiteSpace: "nowrap" }}>Phone No</th>
                <th>Message</th>
                <th>Region from</th>
                <th>Source</th>
                <th>File</th>
                <th style={{ whiteSpace: "nowrap" }}>Is Read</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.data?.map((dt, i) => (
                <tr key={i} onDoubleClick={() => {
                  handleShow(dt);
                }}>
                  <td>{i + 1}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {dt.name ? dt.name : <i>NA</i>}
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {dt.phone ? dt.phone : <i>NA</i>}
                  </td>
                  <td>
                    {dt.body.substring(0, 150)}{" "}
                    {dt.body.length > 150 && <a href="#">... read more</a>}{" "}
                  </td>
                  <td>{dt.address}</td>
                  <td>{dt.source}</td>
                  <td>{dt.file ? (<BsFillFileEarmarkTextFill className="text-secondary" />):("")}</td>
                  <td>
                    <center>
                      {dt.isRead === 1 ? (
                        <BsCheck2Circle className="text-success" />
                      ) : (
                        <BsXCircle className="text-danger" />
                      )}
                    </center>
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <DateFormat date={dt.created_at} />
                  </td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        handleShow(dt);
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          dataLength={data.total}
          pageCount={data.last_page}
          handlePageClick={handlePageClick}
        />
      </div>
      <ViewComplain show={show} setShow={setShow} viewData={viewData} />
    </>
  );
};
