import React from "react";
import { Table } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { AwarenessComp } from "./awarenessComp";

export const Home = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="space">
      <h2 className="red">{t("intro")}</h2>
      <p>
        Awareness unit against corruption is an important unit in the fight
        against corruption and economic crimes in the country in accordance with
        Section 13 ( 1 ) ( f ) and ( h ) of the Zanzibar Anti-Corruption and
        Economic Crimes Act requires the Authority to have the unit that provide
        community education on the risk of corruption and economic crimes and
        encouraging Zanzibar government officials to have values and also
        handles control of corruption in the private sector and finally put an
        alternative initiatives to plug the loopholes of corruption and economic
        crimes. Articles 13 of UNCAC requires each state party participating in
        combating and preventing corruption involving individuals and groups
        such as public institutions , civil society , non- governmental and
        private sectors so ZAECA aims to implement the section and article 13 of
        UNCAC.
      </p>
      <p>
        Awareness Unit has made great efforts to achieve the main goal of
        educating the public about corruption and economic crimes and they are
        effects for the purpose of involving public to prevent corruption and
        economic crimes and changing of people's attitude towards corruption.
      </p>
      <h2 className="red">{t("effort")}</h2>
      <AwarenessComp />
      {/* <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Number</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Radios Programs</td>
            <td>67</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Televisions Programs</td>
            <td>09</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Meeting with Leaders of Ministry</td>
            <td>08</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Meeting with Police Force</td>
            <td>01</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Seminar</td>
            <td>06</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Meeting with Entreprenuers</td>
            <td>01</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Meeting with Secondary Schools's Students</td>
            <td>09</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Exhibitions</td>
            <td>03</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Celebrations</td>
            <td>01</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Meeting with Local Government Leaders</td>
            <td>10</td>
          </tr>
        </tbody>
      </Table> */}
    </div>
  );
};
