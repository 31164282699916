import React, { useState, useEffect } from "react";
import { Card, Button, Col, Row, Spinner } from "react-bootstrap";
import { hotNewsList } from "../../action/News";
import parse from "html-react-parser";
import newsPic from "../../asset/annoucement.jpg";
import { ReadNews } from "./readNews";
import { baseURL } from "../../action/baseUrl";

export const HotEvent = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [read, setRead] = useState(false);
  const [index, setIndex] = useState(1);
  const [newsTitle, setNewsTitle] = useState("");
  const [newsBody, setNewsBody] = useState("");
  const [newsImage, setNewsImage] = useState("");
  const [newsFile, setNewsFile] = useState("");
  const [newsDate, setNewsDate] = useState("");
  const [msgError, setMsgError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      hotNewsList()
        .then((resp) => {
          setData(resp.data);
          // console.warn("xxxxxxxxxxxxx");
          // console.warn(resp.data);
        })
        .catch((error) => {
          // setError(error);
          setLoading(false);
          console.log(error.toJSON());
          setMsgError(error.toJSON().message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 10);
  }, [index]);

  const handleReadNews = (data) => {
    console.warn(data);
    setRead(true);
    setNewsDate(data.created_at);
    setNewsBody(data.detail);
    setNewsTitle(data.title);
    setNewsImage(data.image);
    setNewsFile(data.file);
  };

  return (
    <Row>
      {msgError ? (
        <Button variant="info rounded-0 form-control btn-lg" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="lg"
            role="status"
            aria-hidden="true"
          />
          <br />
          Loading Something...
        </Button>
      ) : (
        data.data?.map((dt, i) => (
          <Col lg={4} md={4} sm={12} key={i}>
            <Card className="rounded-0 cardHover">
              {dt.image === null ? (
                <Card.Img  src={newsPic} />
              ) : (
                <Card.Img
                  
                  src={`${baseURL}/storage/${dt.image}`}
                />
              )}

              <Card.Body>
                <p>{dt.title}</p>
                <Card.Text>
                  {/* <div style={{ fontSize: "12px" }}>
                  {parse(dt.detail.substring(0, 150))} ....
                </div> */}
                </Card.Text>
                <Button
                  variant="info rounded-0"
                  onClick={() => {
                    handleReadNews(dt);
                  }}
                >
                  Read more
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))
      )}
      <ReadNews
        read={read}
        setRead={setRead}
        newsBody={newsBody}
        newsTitle={newsTitle}
        newsDate={newsDate}
        newsImage={newsImage}
      />
    </Row>
  );
};
