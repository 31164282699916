import React from "react";
import { MissionVission as MissionVissionComp } from "../component/missionVission/home";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Menu } from "../component";

export const MissionVission = () => {
  return (
    <Container
      fluid={true}
      className=""
      style={{
        padding: "0px",
      }}
    >
      <Helmet>
        <title>Mission & Vission</title>
      </Helmet>
      <Menu />
      <Container>
        <MissionVissionComp />
      </Container>
      {/* <Footer/> */}
    </Container>
  );
};
