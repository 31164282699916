import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import parse from "html-react-parser";
import { DateFormat } from "../utility/dateFormat";
import image from "../../asset/hot1.png";
import image2 from "../../asset/b1.png";
import { ImAttachment } from "react-icons/im";
import { baseURL } from "../../action/baseUrl";

export const ReadNews = (props) => {
  const { read, setRead, newsTitle, newsBody, newsDate, newsImage, newsFile } =
    props;

  const handleClose = () => setRead(false);

  return (
    <>
      <Modal
        size="lg"
        show={read}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        centered
      >
        <>
          <Modal.Header closeButton>
            <Modal.Title>News</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>{newsTitle}</h3>
            <br />
            {newsImage && (
              <>
                <img
                  src={`${baseURL}/storage/${newsImage}`}
                  alt=""
                  width={"100%"}
                />
                <br />
                <br />
              </>
            )}
            <p style={{ lineHeight: "2.3rem" }}>{parse(newsBody)}</p>
            <span>
              {newsFile && (
                <a
                  href={`${baseURL}/storage/${newsFile}`}
                  className="btn btn-default"
                  target="new"
                >
                  Attachment <ImAttachment />
                </a>
              )}

              <small style={{ float: "right", fontStyle: "italic" }}>
                Posted at <DateFormat date={newsDate} />
              </small>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};
