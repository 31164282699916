import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { DateFormat } from "../../component/utility/dateFormat";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ImAttachment } from "react-icons/im";
import { baseURL } from "../../action/baseUrl";

export const ViewComplain = (props) => {
  const { show, setShow, viewData } = props;

  const handleClose = () => {
    setShow(false);
  };

  const newString = "";

  return (
    <Modal
      size="lg"
      show={show}
      backdrop="static"
      keyboard={false}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Complain details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-4 col-lg-4 col-sm-12">
            <p>
              <b>Name:</b> {viewData.name ? viewData.name : <i>NA</i>}
            </p>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <p>
              <b>Phone:</b> {viewData.phone ? viewData.phone : <i>NA</i>}
            </p>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <p>
              <b>Source:</b> {viewData.source ? viewData.source : <i>NA</i>}
            </p>
          </div>
        </div>
        <p>{viewData.body}</p>
        <span>
          {viewData.file && (
            <a
              href={`${baseURL}/storage/${viewData.file}`}
              className="btn btn-default"
              target="new"
            >
              Attachment <ImAttachment />
            </a>
          )}

          <small style={{ float: "right", fontStyle: "italic" }}>
            Posted at <DateFormat date={viewData.created_at} />
          </small>
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {/* <Button type="submit" variant="primary">
          Save Changes
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};
