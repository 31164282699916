import React from "react";
import { Container } from "react-bootstrap";
import { Redio as RedioComp } from "../component/awareness/radio";
import { Helmet } from "react-helmet-async";
import { Header, Menu, Footer } from "../component";
import "../index.css";

export const Redio = () => {
  return (
    <Container
      fluid={true}
      className=""
      style={{
        padding: "0px",
        // paddingBottom: "50px",
        // background: "#eff8fc",
      }}
    >
      <Helmet>
        <title>Radio Program</title>
      </Helmet>
      <Menu />
      <Container>
        <RedioComp />
      </Container>
    </Container>
  );
};
