import React, { useState, useEffect } from "react";
import { MyCard, Pagination } from "../utility";
import { Col, Row } from "react-bootstrap";
import { Container, Spinner, Button } from "react-bootstrap";
import { publicBarnners } from "../../action/Barnners";
import { DateFormat } from "../utility/dateFormat";
import { baseURL } from "../../action/baseUrl";

export const Home = () => {
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState(undefined);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(1);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      publicBarnners(offset)
        .then((resp) => {
          setData(resp.data.data);
          console.log("dataaa");
          console.log(resp.status);
          console.log(resp.data.data);
        })
        .catch((error) => {
          console.log(error.toJSON().message);
          setMsgError(error.toJSON().message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 10);
  }, [index, limit, offset]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + limit);
  };

  return (
    <div className="space">
      <h2 className="red">Welcome to the ZAECA event's Gallery</h2>
      <p>
        Some photo events are found, these photos archived since the first days
        of launching ZAECA operations end of the year 2013 to date.
      </p>
      <p>
        The photos contain Government officials, ZAECA Officers and General
        public stakeholders
      </p>
      <Row>
        {msgError ? (
          <tr>
            <td colSpan={4}>
              <Button variant="info rounded-0 form-control btn-lg" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
                <br />
                Loading ...
              </Button>
            </td>
          </tr>
        ) : (
          data.data?.map((dt, i) => (
            <Col lg={3} md={4} sm={6} xs={12} key={i}>
              <MyCard
                img={`${baseURL}/storage/${dt.image}`}
                details={`${dt.details.substring(0, 160)} ...`}
                date={<DateFormat date={dt.date} />}
              />
            </Col>
          ))
        )}
      </Row>
      <div className="container" style={{ display: "flex" }}>
        <Pagination
          dataLength={data.total}
          pageCount={data.last_page}
          handlePageClick={handlePageClick}
        />
      </div>
    </div>
  );
};
