import { Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../contex/AuthContext";
import { Layout as AdminLayout } from "../admin/pages/layout";

const PrivateRoute = () => {
  let { auth } = useContext(AuthContext);
  return !auth?.access_token ? <Navigate to="/login" /> : <AdminLayout />;
};

export default PrivateRoute;
