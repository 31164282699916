import { BrowserRouter } from "react-router-dom";
import { Fragment } from "react";
import { Home, Contact, About, Awareness, Resources, Gallery } from "./pages";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { HelmetProvider } from "react-helmet-async";
import { WebRouter, AdminRoute, AuthRouter } from "./route";
// import { Provider } from "react-redux";
// import { store } from "./store/store";
import { AuthProvider } from "./contex/AuthContext";

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  const helmetContext = {};

  return (
    <>
      <HelmetProvider context={helmetContext}>
        {loading ? (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "100vh",
            }}
          >
            <ClipLoader
              color={"#FF0033"}
              loading={loading}
              // cssOverride={}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <BrowserRouter>
            <Fragment>
              <WebRouter />
              <AuthProvider>
                <AdminRoute />
                <AuthRouter />
              </AuthProvider>
            </Fragment>
          </BrowserRouter>
        )}
      </HelmetProvider>
    </>
  );
}

export default App;
