import React, { useContext, useState } from "react";
import logo from "../asset/zaeca.png";
import dayjs from "dayjs";
import AuthContext from "../contex/AuthContext";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../action/baseUrl";

export const Login = () => {
  const date = dayjs().format("YYYY");
  // let { loginUser, errorMsg } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useNavigate();
  let { auth, setAuth } = useContext(AuthContext);

  // let [auth, setAuth] = useState(() =>
  //   localStorage.getItem("auth")
  //     ? JSON.parse(localStorage.getItem("auth"))
  //     : null
  // );
  // let [user, setUser] = useState(() =>
  //   localStorage.getItem("authTokens")
  //     ? JSON.parse(localStorage.getItem("authTokens"))
  //     : null
  // );
  let [loading, setLoading] = useState(true);
  let [errorMsg, setErrorMsg] = useState("");

  const loginUser = async (e) => {
    e.preventDefault();
    let response = await fetch(`${baseURL}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    let data = await response.json();
    console.log("XXXXXXXXXXXXXXXXXX");
    console.log(data);

    if (response.status === 200) {
      setAuth(data);
      // setUser(data.user);
      history("/admin/news");
      localStorage.setItem("auth", JSON.stringify(data));

      console.log("Good job");
    } else if (response.status === 401) {
      // alert('Something went wrong!')
      setErrorMsg("Wrong username or password");
      // console.log(data);
      console.log("Something wet wrong");
    } else if (response.status === 422) {
      setErrorMsg("Something went wrong please try again letter");
    } else {
      if (email === "" || password === "") {
        setErrorMsg("All fields must have value");
      } else {
        setErrorMsg("Unknown error");
        // console.log(data);
      }
    }
  };

  return (
    <section className="h-100">
      <Helmet>
        <title>Login page</title>
      </Helmet>
      <div className="container h-100">
        <div className="row justify-content-sm-center h-100">
          <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
            <div className="text-center my-5">
              <img src={logo} alt="ZAECA LOGO" width={100} />
            </div>
            <div className="card shadow-lg">
              <div className="card-body p-5">
                <h1 className="fs-4 card-title fw-bold mb-4">Login</h1>
                <center className="text-danger fw-bold">{errorMsg}</center>
                <form onSubmit={loginUser}>
                  <div className="mb-3">
                    <label className="mb-2 text-muted" htmlFor="email">
                      E-Mail Address
                    </label>
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      name="email"
                      required=""
                      onChange={(e) => setEmail(e.target.value)}
                      autoFocus={true}
                    />
                    <div className="invalid-feedback">Email is invalid</div>
                  </div>
                  <div className="mb-3">
                    <div className="mb-2 w-100">
                      <label className="text-muted" htmlFor="password">
                        Password
                      </label>
                      <a href="forgot.html" className="float-end">
                        Forgot Password?
                      </a>
                    </div>
                    <input
                      id="password"
                      type="password"
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                      name="password"
                    />
                    <div className="invalid-feedback">Password is required</div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="remember"
                        id="remember"
                        className="form-check-input"
                      />
                      <label htmlFor="remember" className="form-check-label">
                        Remember Me
                      </label>
                    </div>
                    <button type="submit" className="btn btn-primary ms-auto">
                      Login
                    </button>
                  </div>
                </form>
              </div>
              <div className="card-footer py-3 border-0">
                <div className="text-center">
                  <a href="/" className="text-dark">
                    Go to ZAECA Website
                  </a>
                </div>
                {/* <div className="text-center">
                  Don't have an account?{" "}
                  <a href="register.html" className="text-dark">
                    Create One
                  </a>
                </div> */}
              </div>
            </div>
            <div className="text-center mt-5 text-muted">
              Copyright © 2012-{date} — ZAECA
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
