import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { Menu } from "../component";
import { CoreValue as CoreValueComp } from "../component/coreValue/home";

export const CoreValue = () => {
  return (
    <Container
      fluid={true}
      className=""
      style={{
        padding: "0px",
      }}
    >
      <Helmet>
        <title>Core Value</title>
      </Helmet>
      <Menu />
      <Container>
        <CoreValueComp />
      </Container>
    </Container>
  );
};
