import React from "react";
import { Container } from "react-bootstrap";

export const Background = () => {
  return (
    <Container className="space">
      <h3>BACKGROUND</h3>
      <p>
        The United Republic of Tanzania ratified the United Nations Convention
        against Corruption (UNCAC) in 2005. The Convention provides strategic
        guidance to international and national anti-corruption approaches that
        could be adopted by member countries. Article 5(1) of the UNCAC
        stipulates that "Each State Party shall, in accordance with the
        fundamental principles of its legal system, develop and implement or
        maintain effective, coordinated anti-corruption policies that promote
        the participation of society and reflect the principles of the rule of
        law, proper management of public affairs and public property, integrity,
        transparency and accountability."
      </p>
      <p>
        Until recently, Zanzibar did not have a specific anti-corruption body
        and the Tanzania mainland graft body; The Prevention and Combating of
        Corruption Bureau (PCCB) did not have mandate to cover Zanzibar. In 2011
        the Revolutionary Government of Zanzibar (RGOZ) developed and adopted a
        good governance policy and shortly thereafter an Anti-Corruption and
        Economic Crimes Act was passed by the Zanzibar House of Representatives.
        The Act establishes the Zanzibar Anti-Corruption and Economic Crimes
        Authority (ZAECA) operationalized in 2012 with a three-pronged mandate
        of Prevention, Education and Investigations for both public and private
        sector.
      </p>
    </Container>
  );
};
