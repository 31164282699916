import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Spinner, Row, ListGroup, Card } from "react-bootstrap";
import { MyCard } from "../../../component/utility";
// import AuthContext from "../../../contex/AuthContext";
import { Pagination } from "../../../component/utility";
import { DateFormat } from "../../../component/utility/dateFormat";
import { AddRedio } from "./addRedio";
import { listRedio } from "../../../action/Redio";
import { baseURL } from "../../../action/baseUrl";

export const RedioHome = () => {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState(undefined);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(1);
  // const { auth } = useContext(AuthContext);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + limit);
  };

  const handleShow = () => {
    setShow(true);
  };

  // const headers = {
  //   headers: {
  //     "Content-`Type": "application/json",
  //     Authorization: "Bearer" + auth?.access_token,
  //   },
  // };

  useEffect(() => {
    setTimeout(() => {
      listRedio(offset)
        .then((resp) => {
          setData(resp.data.data);
          console.log("dataaa");
          console.log(resp.status);
          console.log(resp.data.data);
        })
        .catch((error) => {
          console.log(error.toJSON().message);
          setMsgError(error.toJSON().message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 10);
  }, [index, limit, offset]);

  return (
    <>
      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Redio table
          <span style={{ float: "right" }}>
            <button className="btn btn-primary" onClick={() => handleShow()}>
              Show
            </button>
          </span>
        </div>
        <div className="card-body table">
          <Row>
            {msgError ? (
              <tr>
                <td colSpan={4}>
                  <Button variant="info rounded-0 form-control btn-lg" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                    <br />
                    Loading ...
                  </Button>
                </td>
              </tr>
            ) : (
              data.data?.map((dt, i) => (
                <Col lg={3} md={4} sm={6} xs={12} key={i}>
                  {/* <MyCard
                    img={`http://127.0.0.1:8000/storage/${dt.audio}`}
                    // details={`${dt.details.substring(0, 160)} ...`}
                    date={<DateFormat date={dt.date} />}
                  /> */}

                  <Card>
                    <audio
                      src={`${baseURL}/storage/${dt.audio}`}
                      controls
                      //   autoplay="false"
                    >
                      {/* Your browser does not support the audio element. */}
                    </audio>
                    <p>
                      {dt.title}
                      <span
                        style={{
                          fontStyle: "italic",
                          fontWeight: "bold",
                          float: "right",
                          fontSize: "small",
                        }}
                      >
                        Recorded at: <DateFormat date={dt.date} />
                      </span>
                    </p>
                  </Card>
                </Col>
              ))
            )}
          </Row>
          {/* {dt.body.substring(0, 150)}{" "}
          {dt.body.length > 150 && <a href="#">... read more</a>}{" "} */}
        </div>
        <div className="container" style={{ display: "flex" }}>
          <Pagination
            dataLength={data.total}
            pageCount={data.last_page}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
      <br />

      <AddRedio
        show={show}
        setShow={setShow}
        // viewData={viewData}
        index={index}
        setIndex={setIndex}
      />
    </>
  );
};
