import React, { useState } from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import { FaBullhorn } from "react-icons/fa";
import dg from "../../asset/dg_profile.png";
import { NewsEvent } from "./newsEvent";
import { useTranslation } from "react-i18next";
import { ComplainForm } from "./complainForm";
import pic from "../../asset/Sequence-01_1.gif";

export const RightSide = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <div style={{ marginTop: "20px" }}>
      <Button
        className="rounded-0 btn-info form-control btn-lg"
        style={{ marginBottom: "0" }}
        onClick={() => setShow(true)}
      >
        <span className="fs-5">
          <FaBullhorn className="fs-3" />
        </span>
        &nbsp; &nbsp; KUTOA TAARIFA
      </Button>
      <Card style={{ marginTop: "0" }}>
        <Card.Body>
          <i style={{ fontSize: "14px", color: "red" }}>
            <b>#</b> Unapotoa taarifa sio lazima kwa mtoaji wa taarifa kuandika
            jina lake, nambari yake ya simu, na wala mahala anapoishi.
            <br />
            <b>#</b> Taarifa yako ni siri.
          </i>
          <br />
          <a href="#" onClick={() => setShow(true)}>
            Kutoa taarifa kupitia tovuti bonyeza hapa{" "}
          </a>
        </Card.Body>
      </Card>
      <NewsEvent />
      <Card className="rounded-0">
        <img src={pic} width={"100%"} />
      </Card>

      <ComplainForm show={show} setShow={setShow} />
    </div>
  );
};
