import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import {
  Home,
  Contact,
  About,
  Awareness,
  Resources,
  Gallery,
  AllNews,
  OrgStructure,
  Background,
  MissionVission,
  CoreValue,
  Redio,
} from "../pages";
import { Header, Footer } from "../component/";

export const WebRouter = () => {
  const WebLayout = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  };

  return (
    <Routes>
      <Route path="/" element={<WebLayout />}>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/coreValue" element={<CoreValue />} />
        <Route exact path="/MissionVission" element={<MissionVission />} />
        <Route exact path="/background" element={<Background />} />
        <Route
          exact
          path="/organisation/structure"
          element={<OrgStructure />}
        />
        {/* <Route exact path="/organisation/unit/structre" element={<About />} /> */}
        <Route exact path="/awareness" element={<Awareness />} />
        <Route exact path="/awareness/redio/program" element={<Redio />} />
        <Route exact path="/resources" element={<Resources />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/news/events" element={<AllNews />} />
      </Route>
    </Routes>
  );
};
