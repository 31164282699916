import React, { useEffect, useState, useContext } from "react";
import { NewsTable } from "../component/newsTable";
import { loadNews } from "../../action/News";
import AuthContext from "../../contex/AuthContext";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  BsDatabaseFillLock,
  BsEnvelopeCheckFill,
  BsEnvelopeXFill,
} from "react-icons/bs";

export const News = () => {
  const history = useNavigate();
  // const { auth, logoutUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(1);
  const [read, setRead] = useState(0);
  const [notRead, setNotRead] = useState(0);
  const { auth, logoutUser } = useContext(AuthContext);

  const headers = {
    headers: {
      "Content-`Type": "application/json",
      Authorization: "Bearer" + auth?.access_token,
    },
  };

  const dataaa = () => {
    loadNews(offset, headers)
      .then((resp) => {
        setData(resp.data.data);
        //   setRead(resp.data.read);
        console.log("dataaa");
        console.log(resp.status);
        //   setNotRead(resp.data.not_read);
        console.log(resp.data.data);
      })
      .catch((error) => {
        // history("/login");
        logoutUser();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      dataaa();
    }, 10);
  }, [index, limit, offset]);
  return (
    <>
      <Helmet>
        <title>News & Event</title>
      </Helmet>
      <div className="container-fluid px-4">
        <h1 className="mt-4 text-dark">Dashboard</h1>
        {/* Table */}
        <NewsTable
          data={data}
          offset={offset}
          setOffset={setOffset}
          setIndex={setIndex}
          index={index}
        />
      </div>
    </>
  );
};
