import axios from "axios";
import { baseURL } from "./baseUrl";

export function addRedio(data, headers) {
  return axios.post(
    `${baseURL}/api/redio/store`,
    data,
    headers
  );
}

export function listRedio() {
  return axios.get(`${baseURL}/api/redio`);
}
