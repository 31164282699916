import React, { useState, useEffect } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import { listBarnners } from "../../action/Barnners";
import defaultB from "../../asset/default.png";
import defaultB1 from "../../asset/lb1.jpg";
import defaultB2 from "../../asset/lb2.jpg";
import defaultB3 from "../../asset/lb3.jpg";
import defaultB4 from "../../asset/lb4.jpg";
import { LeftSide } from "./leftSide";
import { baseURL } from "../../action/baseUrl";

export const Barner = () => {
  const [msgError, setMsgError] = useState("");
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(0);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      listBarnners(offset)
        .then((resp) => {
          setData(resp.data.data);
          console.log("dataaa");
          console.log(resp.status);
          console.log(resp.data.data.data);
        })
        .catch((error) => {
          console.log(error.toJSON().message);
          setMsgError(error.toJSON().message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 10);
  }, [index, limit, offset]);
  return (
    <>
      <Container fluid={true} style={{ padding: "0", marginTop: "20px" }}>
        <Carousel fade>
          {/* <Carousel.Item>
            <img src={defaultB} width={"100%"} alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src={defaultB1} width={"100%"} alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src={defaultB2} width={"100%"} alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src={defaultB3} width={"100%"} alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img src={defaultB4} width={"100%"} alt="" />
          </Carousel.Item> */}
          {data.data?.length >= 0 ? (
                data.data?.map((dt, i) => (
                  <Carousel.Item key={i}>
                    <img
                      src={`${baseURL}/storage/${dt.image}`}
                      width={"100%S"}
                      alt=""
                    />
                  </Carousel.Item>
                ))
              ) : (
                <Carousel.Item>
                  <img src={defaultB} width={"100%"} alt="" />
                </Carousel.Item>
              )}
        </Carousel>
      </Container>
    </>
  );
};
