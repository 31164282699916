import React, { useState, useRef, useContext } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addNews } from "../../action/News";
// import JoditEditor from "jodit-react";
import AuthContext from "../../contex/AuthContext";
import Swal from "sweetalert2";

export const AddNews = (props) => {
  const { show, setShow, index, setIndex } = props;
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { auth } = useContext(AuthContext);

  const handleClose = () => {
    setShow(false);
    formik.resetForm();
    setContent("");
  };

  const headers = {
    headers: {
      Authorization: "Bearer" + auth?.access_token,
      "Content-Type": "multipart/form-data",
    },
  };
  // const FILE_SIZE = 1024 * 1024;
  // const IMG_SUPPORTED_FORMATS = [
  //   "image/jpg",
  //   "image/jpeg",
  //   "image/gif",
  //   "image/png",
  // ];

  const productSchema = Yup.object().shape({
    title: Yup.string().min(3).required("Title required"),
    detail: Yup.string().min(5).required("Details required"),
    file: Yup.mixed().test(
      "fileSize",
      "File size too large, max file size is 1 Mb",
      (file) => {
        if (file) {
          return file.size <= 1024 * 1024;
        } else {
          return true;
        }
      }
    ),
    image: Yup.mixed()
      // .test(
      //   "fileSize",
      //   "File too large",
      //   (value) => value && value.size <= FILE_SIZE
      // )
      .test(
        "fileSize",
        "File size too large, max file size is 1 Mb",
        (file) => {
          if (file) {
            return file.size <= 1024 * 1024;
          } else {
            return true;
          }
        }
      ),
    // .test(
    //   "fileFormat",
    //   "Unsupported Format. Supported format are jpg, jpeg",
    //   (value) => value && IMG_SUPPORTED_FORMATS.includes(value.type)
    // ),
  });

  const initialValues = {
    title: "",
    file: "",
    image: "",
    detail: undefined,
    user_id: 1,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: productSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { title, user_id, file, image, detail } = values;
      const data = { title, user_id, file, image, detail };
      console.warn(data);

      setTimeout(() => {
        addNews(data, headers)
          .then((resp) => {
            setIndex(index + 1);

            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              icon: "success",
              title: "News created successfully",
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setSubmitting(false);
            resetForm();
            setShow(false);
          });
      }, 10);
    },
  });

  return (
    <>
      <Modal
        size="lg"
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        centered
      >
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>News & Event Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="tetx"
                placeholder="Title"
                autoFocus
                {...formik.getFieldProps("title")}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.title}</span>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Detail</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                // placeholder="detail....."
                {...formik.getFieldProps("detail")}
              />
              {formik.touched.detail && formik.errors.detail && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.detail}</span>
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload image (Size: 110cm X 62cm)</Form.Label>

              <Form.Control
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={(e) =>
                  formik.setFieldValue("image", e.target.files[0])
                }
              />
              {formik.touched.image && formik.errors.image && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.image}</span>
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Attach file</Form.Label>

              <Form.Control
                type="file"
                accept=".pdf"
                onChange={(e) =>
                  formik.setFieldValue("file", e.target.files[0])
                }
              />
              {formik.touched.file && formik.errors.file && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.file}</span>
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              // disabled={formik.isSubmitting || !formik.isValid}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
