import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import logo_eng from "../asset/zaeca.png";
// import znz from "../asset/Zanzibar-logo.png";
import bg_eng from "../asset/zaeca-banner.png";
import bg_sw from "../asset/zaeca-banner_sw.png";
import { useTranslation } from "react-i18next";
import { BsFacebook, BsTwitterX, BsInstagram, BsYoutube } from "react-icons/bs";
import { FaBullhorn } from "react-icons/fa";
import Marquee from "react-fast-marquee";
import { publicSlogan } from "../action/Barnners";
// import { Trans, useTranslation } from "react-i18next";

export const Header = () => {
  // const { t, i18n } = useTranslation();
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState(null);
  const [index, setIndex] = useState(0);

  const defaultLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "sw";

  useEffect(() => {
    setTimeout(() => {
      publicSlogan()
        .then((resp) => {
          setData(resp.data);
          console.warn(resp.data);
          setLoading(false);
        })
        .catch((error) => {
          // setError(error);
          // setLoading(false);
          console.log(error.toJSON().message);
          setMsgError(error.toJSON().message);
        });
    }, 10);
  }, [index]);

  return (
    <>
      <Container
        fluid={true}
        style={{
          paddingTop: "7px",
          paddingBottom: "5px",
          borderBottom: "#78ACD6",
          borderBottomStyle: "solid",
          background: "#e8e8e8",
        }}
      >
        <Container>
          <Row>
            <Col lg={10} md={10} sm={12}>
              {defaultLocale === "en" ? (
                <img src={bg_eng} width={"100%"} alt="" />
              ) : (
                <img src={bg_sw} width={"100%"} alt="" />
              )}
            </Col>
            <Col lg={2} md={2} sm={12} className={"text-center"}>
              <div className="container social">
                <a
                  href="https://www.facebook.com/profile.php?id=100068866193864&mibextid=ZbWKwL"
                  target="new"
                >
                  <BsFacebook style={{ color: "#0766ff" }} />
                </a>
                &nbsp; &nbsp;
                <a
                  href="https://x.com/ZaecaZ?t=Y0AjEBQflqCkVs6VuXuURQ&s=09"
                  target="new"
                >
                  <BsTwitterX style={{ color: "black" }} />
                </a>
                &nbsp; &nbsp;
                <a href="https://www.instagram.com/zaeca.znz" target="new">
                  <BsInstagram style={{ color: "#c8325c" }} />
                </a>
                &nbsp; &nbsp;
                <a
                  href="https://youtube.com/@zaecaonlinetv7808?si=Jn07JOlfGvtdfv7i"
                  target="new"
                >
                  <BsYoutube style={{ color: "#ff1512" }} />
                </a>
              </div>
              <br />
              {/* <br /> */}
              {/* <Button className="btn-info btn-sm ">
                <span className="fs-5">
                  <FaBullhorn className="fs-5" />
                </span>
                &nbsp; &nbsp; Tota Taarifa
              </Button> */}
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        fluid={true}
        style={{
          paddingLeft: "0",
          paddingRight: "0",
          borderBottom: "#78ACD6",
          borderBottomStyle: "solid",
          fontWeight: "bold",
          color: "red",
        }}
      >
        <Marquee
          behavior="scroll"
          pauseOnHover={true}
          gradientColor={"#5aad42"}
          gradient={true}
        >
          {/* {t("Mottor.msg")} */}
          {defaultLocale === "en" ? data.data?.english : data.data?.swahili}
        </Marquee>
      </Container>
    </>
  );
};
