import React from "react";
import { AllNews as AllNewsComp } from "../component/home/allNews";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { Footer, Header, Menu } from "../component";
import "../index.css";

export const AllNews = () => {
  return (
    <div
      fluid={true}
      className=""
      style={{
        padding: "0px",
        // paddingBottom: "50px",
        // background: "#eff8fc",
      }}
    >
      <Helmet>
        <title>News & Event</title>
      </Helmet>
      {/* <Header /> */}
      <Menu />
      <Container>
        <AllNewsComp />
      </Container>
      {/* <Footer /> */}
    </div>
  );
};
