import React from "react";
import { Home } from "../component/resources";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { Footer, Header, Menu } from "../component";
import "../index.css";

export const Resources = () => {
  return (
    <div
      fluid={true}
      className=""
      style={{
        padding: "0px",
        // paddingBottom: "50px",
        // background: "#eff8fc",
      }}
    >
      <Helmet>
        <title>Resources</title>
      </Helmet>
      {/* <Header /> */}
      <Menu />
      <Container>
        <Home />
      </Container>
      {/* <Footer /> */}
    </div>
  );
};
