import React from "react";
import { Modal, Button } from "react-bootstrap";

export const DGMessage = (props) => {
  const { show, setShow } = props;
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        centered
      >
        <>
          <Modal.Header closeButton>
            <Modal.Title>DG MESSAGE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <h3>Director General Message</h3>
            </center>
            <p>
              Mamlaka ya Kuzuia Rushwa na Uhujumi uchumi Zanzibar (ZAECA)
              imeanzishwa chini ya Sheria ya Kuzuia Rushwa na Uhujumu uchumi
              Zanzibar sheria Namba 5 ya mwaka 2023. Sehemu ya majukumu ya
              Mamlaka kama ilivyoelezwa kwenye kifungu cha 5 cha sheria, inasema
            </p>
            <p>
              5(1)(e) Kwa ombi la mtu au taasisi yoyote, kumshauri na kumsaidia
              mtu au taasisi juu ya njia ambazo mtu au taasisi hiyo inaweza
              kuzuia vitendo vya rushwa;
            </p>
            <p>
              5(1)(h) Kuelimisha umma juu ya hatari za rushwa na uhujumu wa
              uchumi na umuhimu wa kanuni za maadili kutafuta na kukuza msaada
              wa umma katika kupambana na rushwa na uhalifu.
            </p>
            <p>
              Mamlaka imedhamiria kuzuia, kuchunguza na kwa idhini ya Mkurugenzi
              wa Mashtaka (DPP), kushtaki makosa ya kiuchumi (kifedha) ambayo
              yanaongezeka, Uhalifu wa Kielektroniki ni aina ya wizi unaotokea
              wakati mtu au shirika linachukua pesa au mali, au kuzitumia kwa
              njia isiyo halali, kwa nia ya kupata faida kutoka kwao. Uhalifu
              huu kwa kawaida huhusisha aina fulani ya udanganyifu, hila au
              matumizi mabaya ya ofisi, ambayo huwatofautisha na wizi wa
              kawaida. Katika uchumi tata wa kisasa, ulaghai na uhalifu wa
              kifedha unaweza kuchukua aina nyingi; kughushi, utapeli wa kadi za
              mkopo, ubadhirifu na utakatishaji fedha.
            </p>
            <p>
              Usafirishaji haramu wa pesa hutumiwa zaidi kwa ufadhili wa kigaidi
              ambao huibua maswala muhimu kuhusu kuzuia, kugundua na kufunguliwa
              mashtaka. Mbinu za kisasa zinazotumiwa kutakatisha fedha na
              kufadhili ugaidi huongeza utata wa masuala hayo
            </p>
            <p>
              ZAECA inatoa wito kwa taasisi zote zinazohusika na miamala ya
              fedha kuripoti miamala yoyote inayoonekana kutiliwa shaka ndani ya
              benki, pesa kwa njia ya simu (Ezy-pesa, m-pesa, tigo pesa na
              Airtel money) na taasisi nyingine zozote zinazohusika na
              uhaulishaji fedha.
            </p>
            <p>
              Mkurugenzi Mkuu wa ZAECA anafahamu ukubwa wa vitendo vya rushwa
              miongoni mwa taasisi za umma Zanzibar na hivyo kuwataka wananchi
              wote kushirikiana na Mamlaka katika mapambano hayo.{" "}
            </p>
            <p>Jukumu la kuzuia na kupambana na Rushwa ni letu sote.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};
