import React from "react";
import "../../admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { TopNav } from "../topNav";
import { SideNav } from "../sideNav";
import { Footer } from "../footer";
import { Route, Routes, Outlet } from "react-router-dom";
import { Home } from "./complain";
import { News } from "./news";

export const Layout = () => {
  return (
    <>
      {/* top */}
      <TopNav />
      <div id="layoutSidenav">
        {/* Side */}
        {/* <SideNav /> */}

        <div id="layoutSidenav_content">
          <main>
            <Outlet />
          </main>
          {/* footer */}
          <Footer />
        </div>
      </div>
    </>
  );
};
