import axios from "axios";
import { baseURL } from "./baseUrl";

export function addComplain(data, headers) {
  return axios.post(
    `${baseURL}/api/complain`,
    data,
    headers
  );
}

export function isRead(ids, data, headers) {
  return axios.post(
    `${baseURL}/api/complain/${ids}/update`,
    data,
    headers
  );
}

export function loadComplain(offset, headers) {
  return axios.get(
    `${baseURL}/api/complain?page=${offset}`,
    headers
  );
}



