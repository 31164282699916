import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Spinner, Row, Card } from "react-bootstrap";
import { AddBarnners } from "./addBarnners";
import { MyCard } from "../../../component/utility";
import { listBarnners } from "../../../action/Barnners";
import AuthContext from "../../../contex/AuthContext";
import { Pagination } from "../../../component/utility";
import { DateFormat } from "../../../component/utility/dateFormat";
import { baseURL } from "../../../action/baseUrl";
import Swal from "sweetalert2";
import { deleteBanner } from "../../../action/Barnners";

export const BarnnerHome = () => {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState(undefined);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(1);
  const { auth } = useContext(AuthContext);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + limit);
  };

  const handleShow = () => {
    setShow(true);
  };

  const headers = {
    headers: {
      "Content-`Type": "application/json",
      Authorization: "Bearer" + auth?.access_token,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      listBarnners(offset, headers)
        .then((resp) => {
          setData(resp.data.data);
          console.log("dataaa");
          console.log(resp.status);
          console.log(resp.data.data);
        })
        .catch((error) => {
          console.log(error.toJSON().message);
          setMsgError(error.toJSON().message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 10);
  }, [index, limit, offset]);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You wont to delete news !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        console.warn("ID XXXXXXXX");
        console.log(id);
        deleteBanner(id, headers).then((resp) => {});
        setIndex(index + 1);
        Swal.fire({
          title: "Deleted!",
          text: "Image Banner has been deleted.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  return (
    <>
      <div className="card mb-4">
        <div className="card-header">
          <i className="fas fa-table me-1"></i>
          Complain table
          <span style={{ float: "right" }}>
            <button className="btn btn-primary" onClick={() => handleShow()}>
              Barnners
            </button>
          </span>
        </div>
        <div className="card-body table">
          <Row>
            {msgError ? (
              <tr>
                <td colSpan={4}>
                  <Button variant="info rounded-0 form-control btn-lg" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                    <br />
                    Loading ...
                  </Button>
                </td>
              </tr>
            ) : (
              data.data?.map((dt, i) => (
                <Col lg={3} md={4} sm={6} xs={12} key={i}>
                  {/* <MyCard
                    img={`${baseURL}/storage/${dt.image}`}
                    id={dt.id}
                    index={index}
                    setIndex={setIndex}
                  /> */}
                  <Card>
                    <Card.Img
                      variant="top"
                      src={`${baseURL}/storage/${dt.image}`}
                    />
                    <Card.Body>
                      {/* <Card.Title>{title}</Card.Title> */}
                      {/* <Card.Text>
                        <p>{details}</p>
                        <small style={{ float: "right", fontStyle: "italic" }}>
                          {date}
                        </small>
                      </Card.Text> */}

                      <Button
                        onClick={() => {
                          handleDelete(dt.id);
                        }}
                        className="btn-sm"
                        variant="danger"
                      >
                        Delete
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
          {/* {dt.body.substring(0, 150)}{" "}
          {dt.body.length > 150 && <a href="#">... read more</a>}{" "} */}
        </div>
        <div className="container" style={{ display: "flex" }}>
          <Pagination
            dataLength={data.total}
            pageCount={data.last_page}
            handlePageClick={handlePageClick}
          />
        </div>
      </div>
      <br />

      <AddBarnners
        show={show}
        setShow={setShow}
        // viewData={viewData}
        index={index}
        setIndex={setIndex}
      />
    </>
  );
};
