import React, { useState } from "react";
import { Container, ListGroup, Card, Button, Row, Col } from "react-bootstrap";
import dg from "../../asset/Ali_Abdalla.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HotEvent } from "./hotEvent";
import hot1 from "../../asset/b1.png";
import hot2 from "../../asset/b2.png";
import hot3 from "../../asset/b3.png";
import { Washirika } from "./washirika";
import { ReadNews } from "./readNews";
import { LeftSide } from "./leftSide";
import { Barner } from "../home/barner";

export const Welcome = () => {
  const [read, setRead] = useState(false);
  const { t, i18n } = useTranslation();
  const [newsTitle, setNewsTitle] = useState("");
  const [newsBody, setNewsBody] = useState("");
  const [newsDate, setNewsDate] = useState("");

  const handleReadNews = (data) => {
    setRead(true);
    setNewsDate(data.created_at);
    setNewsBody(data.detail);
    setNewsTitle(data.title);
  };

  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  // };
  return (
    <>
      <Container>
        {/* <Barner /> */}
        {/* <div className="container-fluid"> */}
        <Row>
          <Col md={4}>
            <LeftSide />
          </Col>
          <Col md={8}>
            <Barner />
          </Col>
        </Row>
        {/* </div> */}
        <br />
        <h1 className="red">{t("welcome")}</h1>
        {/* <hr /> */}
        <p style={{ lineHeight: "2.2rem" }}>
          Mamlaka ya kuzuia Rushwa na Uhujumu Uchumi Zanzibar (ZAECA) ni Taasisi
          ya Serekali ya Mapinduzi ya Zanzibar iliyoanzishwa chini ya Sheria
          nambari 5 ya mwaka 2023 Sheria ya Kuzuia Rushwa na Uhujumu Uchumi
          Zanzibar.
        </p>
        <h3>{t("function.intro")}</h3>
        <p>{t("function.1")}</p>
        <p>{t("function.2")}</p>
        <p>{t("function.3")}</p>
        <p>{t("function.4")}</p>
        <p>{t("function.5")}</p>
        <p>{t("function.6")}</p>
      </Container>

      <HotEvent />
      <ReadNews
        read={read}
        setRead={setRead}
        newsBody={newsBody}
        newsTitle={newsTitle}
        newsDate={newsDate}
      />
      {/* <Washirika /> */}
    </>
  );
};
