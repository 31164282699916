import React from "react";
import { Container, Col, Accordion, Row } from "react-bootstrap";

export const CoreValue = () => {
  return (
    <Container className="space">
      <h3>CORE VALUES</h3>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {/* <BsGeoAlt className="newsIcon" /> */}
            Core Values
          </Accordion.Header>
          <Accordion.Body>
            <p>
              INTERGRITY: Acting in an honest, fair accountability and
              transparent manner.
              <br />
              FIDELITY OF LAW: Respecting and applying the law consistently and
              impartially.
              <br />
              COMMITMENT & PROFESSIONALISM: Maintaining highest standard of
              achievement and working competently, skilfully and ethical.
              <br />
              COURAGE: Enforcing the anti-corruption laws firmly and without
              fear or favour.
              <br />
              EXCELLENCE IN SERVICES: Upholding highest standards in our
              services delivery to all.
              <br />
              TEAM WORK: Building cooperate ideas to fully tap the rich and
              multi-skilled human resource base of the Authority to achieve our
              mandate.
              <br />
              COOPERATION: Engage the society, public and private sectors and
              international community.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Expected Responsibilities to Each Individual
          </Accordion.Header>
          <Accordion.Body>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item">Not to give or receive bribe</li>
              <li class="list-group-item">
                Educate others on effect of corruption and economic crimes
              </li>
              <li class="list-group-item">
                Be trustfully and honest in service delivery
              </li>
              <li class="list-group-item">
                Report corruption and economic crimes to ZAECA and other
                responsible institutions
              </li>
              <li class="list-group-item">
                Respect and protect public properties
              </li>
              <li class="list-group-item">
                Demand receipt in business transaction
              </li>
              <li class="list-group-item">
                Avoid engaging in corrupt practice
              </li>
              <li class="list-group-item">Never falsify a claim</li>
              <li class="list-group-item">Be ready to cooperate</li>
            </ol>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>ZAECA Assures</Accordion.Header>
          <Accordion.Body>
            <ol class="list-group list-group-numbered">
              <li class="list-group-item">High cooperation</li>
              <li class="list-group-item">Confidentiality</li>
              <li class="list-group-item">Expediency</li>
              <li class="list-group-item">Integrity and competence</li>
            </ol>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};
