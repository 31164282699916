import React, { useEffect, useState, useContext } from "react";
import { HomeTable } from "../component/table";
import { loadComplain } from "../../action/Complain";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contex/AuthContext";
import { Helmet } from "react-helmet-async";
import {
  BsDatabaseFillLock,
  BsEnvelopeCheckFill,
  BsEnvelopeXFill,
} from "react-icons/bs";

export const Home = () => {
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(1);
  const [read, setRead] = useState(0);
  const [notRead, setNotRead] = useState(0);
  const history = useNavigate();
  const { auth, logoutUser } = useContext(AuthContext);

  const headers = {
    headers: {
      "Content-`Type": "application/json",
      Authorization: "Bearer" + auth?.access_token,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      loadComplain(offset, headers)
        .then((resp) => {
          setData(resp.data.data);
          setRead(resp.data.read);
          setNotRead(resp.data.not_read);
        })
        .catch((error) => {
          // history("/login");
          logoutUser();
        })
        .finally(() => {
          setLoading(false);
        });
    }, 10);
  }, [index, limit, offset]);
  return (
    <>
      <Helmet>
        <title>Complain</title>
      </Helmet>
      <div className="container-fluid px-4">
        <h1 className="mt-4 text-dark">Dashboard</h1>
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div className="card bg-primary text-white mb-4">
              <div className="card-body">
                <span className="cardNo">
                  <BsDatabaseFillLock />
                  <span style={{ float: "right" }}>{data?.total}</span>
                </span>
              </div>
              <div className="card-footer d-flex align-items-center justify-content-between">
                Total complain
                <div className="small text-white">
                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div className="card bg-success text-white mb-4">
              <div className="card-body">
                <span className="cardNo">
                  <BsEnvelopeCheckFill />
                  <span style={{ float: "right" }}>{read}</span>
                </span>
              </div>
              <div className="card-footer d-flex align-items-center justify-content-between">
                Total Viewed Complain
                <div className="small text-white">
                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <div className="card bg-danger text-white mb-4">
              <div className="card-body">
                <span className="cardNo">
                  <BsEnvelopeXFill />
                  <span style={{ float: "right" }}>{notRead}</span>
                </span>
              </div>
              <div className="card-footer d-flex align-items-center justify-content-between">
                Not Read Complain
                <div className="small text-white">
                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Table */}
        <HomeTable
          data={data}
          offset={offset}
          setOffset={setOffset}
          setIndex={setIndex}
          index={index}
        />
      </div>
    </>
  );
};
