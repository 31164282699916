import { createContext, useState, useEffect } from "react";
import { useNavigate, redirect } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [auth, setAuth] = useState(() =>
    localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth"))
      : null
  );

  let [loading, setLoading] = useState(true);
  let [errorMsg, setErrorMsg] = useState("");
  // let [response, setResponse] = useState(null);

  const history = useNavigate();

  let logoutUser = () => {
    // setAuthTokens(null);
    // setUser(null);
    localStorage.removeItem("auth");
    history("/login", { replace: true });
    // redirect("/login");
  };

  let updateToken = async () => {
    let response = await fetch("http://127.0.0.1:8000/api/refresh", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ token: auth?.access_token }),
    });
    let data = await response.json();

    if (response.status === 200) {
      setAuth(data);
      // // setUser(data.user);
      localStorage.setItem("auth", JSON.stringify(data));
      // history("/news");
      console.log("gooooooooooooooooooooo");
      console.log(data);
    } else {
      // alert('Something went wrong!')
      // setErrorMsg("Wrong username or password");
      console.log("Wrooooooooooooooooooooooong");
      logoutUser();
    }
  };

  let contextData = {
    // user: user,
    auth: auth,
    setAuth: setAuth,
    // loginUser: loginUser,
    logoutUser: logoutUser,
    // errorMsg: errorMsg,
  };

  useEffect(() => {
    // if (loading) {
    //   updateToken();
    // }

    let fourMinutes = 1000 * 60 * 20;

    let interval = setInterval(() => {
      if (auth) {
        updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [auth]);

  return (
    <AuthContext.Provider value={contextData}>
      {children}
      {/* {PrivateRoute} */}
      {/* {loading ? null : children} //Layout */}
    </AuthContext.Provider>
  );
};
