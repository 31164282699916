import ReactPaginate from "react-paginate";
import "../../pagination.css";

export const Pagination = (props) => {
  const { pageCount, handlePageClick, dataLength } = props;

  return (
    <>
      {dataLength <= 0 && (
        <h5 style={{ textAlign: "center", marginTop: "50px" }}>
          No data found ....
        </h5>
      )}
      {dataLength > 0 && (
        <ReactPaginate
          previousLabel={<span aria-hidden="true">&laquo;</span>}
          nextLabel={<span aria-hidden="true">&raquo;</span>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination float-end rounded-3"}
          subContainerClassName={"pages pagination "}
          activeClassName={"active"}
        />
      )}
    </>
  );
};
