import React from "react";
import { useTranslation } from "react-i18next";

export const Home = () => {
  const [t] = useTranslation();
  return (
    <div className="space">
      <h2 className="red">{t("background.intro")}</h2>
      <p>{t("background.one")}</p>
      <p>{t("background.two")}</p>
      <h3>{t("background.three")}</h3>
      <p>{t("background.four")}</p>
      <p>{t("background.five.1")}</p>
      <p>{t("background.five.2")}</p>
      <p>{t("background.five.3")}</p>
      <p>{t("background.five.4")}</p>
      <p>{t("background.five.5")}</p>
      <p>{t("background.five.6")}</p>
      <p>{t("background.five.7")}</p>
      <p>{t("background.six")}</p>
      <p>{t("background.seven")}</p>
      <p>{t("background.eight")}</p>
      <p>{t("background.nine")}</p>
      <p>{t("background.ten")}</p>
    </div>
  );
};
