import React, { useState, useEffect } from "react";
import { BsMegaphoneFill } from "react-icons/bs";
import { ListGroup, Form, Spinner, Button, Card } from "react-bootstrap";
import cmplain from "../../asset/complain.jpg";
import { Link } from "react-router-dom";
import { ComplainForm } from "./complainForm";
import { publicNews } from "../../action/News";
import { DateFormat } from "../utility/dateFormat";
import { ReadNews } from "./readNews";
import { useTranslation } from "react-i18next";
import { ImAttachment } from "react-icons/im";

export const NewsEvent = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [read, setRead] = useState(false);
  const [newsTitle, setNewsTitle] = useState("");
  const [newsBody, setNewsBody] = useState("");
  const [newsImage, setNewsImage] = useState("");
  const [newsFile, setNewsFile] = useState("");
  const [newsDate, setNewsDate] = useState("");
  const [msgError, setMsgError] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      publicNews()
        .then((resp) => {
          setData(resp.data);
          console.warn(resp.data);
          setLoading(false);
        })
        .catch((error) => {
          // setError(error);
          // setLoading(false);
          console.log(error.toJSON().message);
          setMsgError(error.toJSON().message);
        });
    }, 10);
  }, [index]);

  // if (error) return <p>Error: {error.message}</p>;

  const handleReadNews = (data) => {
    console.warn(data);
    setRead(true);
    setNewsDate(data.created_at);
    setNewsBody(data.detail);
    setNewsTitle(data.title);
    setNewsImage(data.image);
    setNewsFile(data.file);
  };

  return (
    <>
      <Button
        className="btn-secondary btn-lg form-control rounded-0"
        style={{ textAlign: "center" }}
      >
        {t("news")}
      </Button>
      {msgError ? (
        <Button variant="info rounded-0 form-control btn-lg" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="lg"
            role="status"
            aria-hidden="true"
          />
          <br />
          Loading News...
        </Button>
      ) : data.data?.length > 0 ? (
        <ListGroup variant="flush">
          {data.data?.map((dt, i) => (
            <div
              onClick={() => {
                handleReadNews(dt);
              }}
              key={i}
            >
              <ListGroup.Item className="newsHover">
                <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                  <BsMegaphoneFill className="newsIcon" />
                  {dt.title}
                  &nbsp; &nbsp;
                  {dt.file && <ImAttachment />}
                  <br />
                  <span className="postedDate">
                    Posted at <DateFormat date={dt.created_at} />
                  </span>
                </div>
              </ListGroup.Item>
            </div>
          ))}

          {/* <ListGroup.Item> */}
          <br />
          <Link className="btn btn-info rounded-0" to={"/news/events"}>
            More news
          </Link>
          {/* </ListGroup.Item> */}
        </ListGroup>
      ) : (
        <center>
          {/* <Spinner animation="border" /> */}
          <p>There is no news found ....</p>
          <hr />
        </center>
      )}

      <ReadNews
        read={read}
        setRead={setRead}
        newsBody={newsBody}
        newsTitle={newsTitle}
        newsDate={newsDate}
        newsImage={newsImage}
        newsFile={newsFile}
      />
    </>
  );
};
