import axios from "axios";
import { baseURL } from "./baseUrl";

export function listGallery(offset, headers) {
  return axios.get(`${baseURL}/api/gallary?page=${offset}`, headers);
}
export function listBarnners(offset, headers) {
  return axios.get(`${baseURL}/api/banner/public?page=${offset}`, headers);
}

export function addBarnners(data, headers) {
  return axios.post(`${baseURL}/api/gallary`, data, headers);
}

export function publicBarnners(offset) {
  return axios.get(`${baseURL}/api/gallary/public?page=${offset}`);
}

export function deleteBanner(id, headers) {
  return axios.delete(`${baseURL}/api/banner/${id}/delete`, headers);
}

export function publicSlogan(offset) {
  return axios.get(`${baseURL}/api/slogan`);
}
