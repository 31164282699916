import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { News, Home as Complain, Barners, Redio,Gallery } from "../admin/pages";
// import { Home as Complain } from "../admin/complain";

export const AdminRoute = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route exact path="/admin/complain" element={<Complain />} />
        <Route exact path="/admin/news" element={<News />} />
        <Route exact path="/admin/barnner" element={<Barners />} />
        <Route exact path="/admin/gallery" element={<Gallery />} />
        <Route exact path="/admin/redio/program" element={<Redio />} />
      </Route>
    </Routes>
  );
};
