import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { Login } from "../admin/login";

export const AuthRouter = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
    </Routes>
  );
}
