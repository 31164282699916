import React from "react";
import { Container, Table, Accordion } from "react-bootstrap";
import { BsCloudDownloadFill } from "react-icons/bs";
import { ResourceTab } from "./resourceTab";
import { useTranslation } from "react-i18next";

export const Home = () => {
  const { t } = useTranslation();
  return (
    <Container className="space">
      <h2 className="red">{t("menu.Resources")}</h2>
      <p>
        The Act No. 1 of 2012 can be downloaded, also some other Acts, Laws,
        Regulations related to corruption and economic crimes can be dowloded as
        well.
      </p>
      <p>
        Publications, brouchers and FAQ's released by ZAECA are available to be
        downloaded.
      </p>
      <ResourceTab />
    </Container>
  );
};
