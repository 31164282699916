import React, { useState, useRef, useContext } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addBarnners } from "../../../action/Barnners";
import Swal from "sweetalert2";
import AuthContext from "../../../contex/AuthContext";

export const AddGallery = (props) => {
  const { show, setShow, index, setIndex } = props;
  const [content, setContent] = useState("");
  const { auth } = useContext(AuthContext);

  const handleClose = () => {
    setShow(false);
    formik.resetForm();
    setContent("");
  };

  const headers = {
    headers: {
      Authorization: "Bearer" + auth?.access_token,
      "Content-Type": "multipart/form-data",
    },
  };
  const productSchema = Yup.object().shape({
    title: Yup.string().required("Required").min(3),
    date: Yup.string().required("Required"),
    image: Yup.mixed()
      .required("Image required")
      .test(
        "fileSize",
        "File size too large, max file size is 1.5 Mb",
        (file) => {
          if (file) {
            return file.size <= 10 * 1024 * 1024;
          } else {
            return true;
          }
        }
      ),
  });

  const initialValues = {
    title: "",
    image: null,
    details: "",
    date: "",
    // user_id: 1,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: productSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { title, image, details, date } = values;
      const data = { title, image, details, date };
      data["user"] = auth.user.id;
      console.warn(data);

      setTimeout(() => {
        data["isBanner"] = 0;
        addBarnners(data, headers)
          .then((resp) => {
            setIndex(index + 1);

            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              icon: "success",
              title: "Barnners created successfully",
            });
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              icon: "error",
              title: "Something went wrong, try again please",
            });
            console.log(error);
          })
          .finally(() => {
            setSubmitting(false);
            resetForm();
            setShow(false);
          });
      }, 10);
    },
  });

  return (
    <>
      <Modal
        size="lg"
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        centered
      >
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Gallery Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="tetx"
                placeholder="Title"
                autoFocus
                {...formik.getFieldProps("title")}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.title}</span>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                // placeholder="detail....."
                {...formik.getFieldProps("details")}
              />
              {formik.touched.details && formik.errors.details && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.details}</span>
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload image (Size: 110cm X 62cm)</Form.Label>

              <Form.Control
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={(e) =>
                  formik.setFieldValue("image", e.target.files[0])
                }
              />
              {formik.touched.image && formik.errors.image && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.image}</span>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                // placeholder="Title"
                autoFocus
                {...formik.getFieldProps("date")}
              />
              {formik.touched.date && formik.errors.date && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.date}</span>
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              // disabled={formik.isSubmitting || !formik.isValid}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
