import React, { useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BsEnvelopeAt, BsTranslate } from "react-icons/bs";
import { ComplainForm } from "./home/complainForm";
import { Trans, useTranslation } from "react-i18next";

export const Menu = () => {
  const { t, i18n } = useTranslation();
  const localeList = ["en", "sw"];
  const currentLocale = localStorage["i18nextLng"]
    ? localStorage["i18nextLng"]
    : "en";

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);

  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  // };

  const onChangeLanguage = (e) => {
    const selectedLocale = e.target.value;
    i18n.changeLanguage(selectedLocale);
  };

  return (
    <div className="sticky-top navbar-expand-lg shadow">
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        data-bs-theme="light"
        className="space"
      >
        <Container>
          {/* <Navbar.Brand className="text-size">ZAECA</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavLink to="/" className="nav-link">
                ZAECA
              </NavLink>
              <NavDropdown
                title={t("menu.About")}
                id="navbarScrollingDropdown"
                onMouseLeave={() => setShowDropdown(false)}
                onMouseOver={() => setShowDropdown(true)}
                show={showDropdown}
                className="red"
                // style={{ color: "#ff0033 !important" }}
              >
                <NavLink className="dropdown-item" to="/about">
                  {t("menu.intro")}
                </NavLink>
                <NavLink className={"dropdown-item"} to="/background">
                  {t("menu.Background")}
                </NavLink>
                <NavLink className={"dropdown-item"} to="/MissionVission">
                  {t("menu.MissionVission")}
                </NavLink>
                <NavLink className={"dropdown-item"} to="/CoreValue">
                  {t("menu.CoreValue")}
                </NavLink>
                <NavLink className="dropdown-item" to="/organisation/structure">
                  {t("menu.OrgStructure")}
                </NavLink>
              </NavDropdown>
              <NavLink to="/contact" className="nav-link">
                {t("menu.Contact")}
              </NavLink>
              <NavLink className="nav-link" to="/awareness">
                {t("menu.Awareness")}
              </NavLink>
              <NavLink className="nav-link" to="/resources">
                {t("menu.Resources")}
              </NavLink>
              <NavLink className="nav-link" to="/gallery">
                {t("menu.Gallery")}
              </NavLink>
              <NavDropdown
                title="USEFUL LINK"
                id="navbarScrollingDropdown"
                onMouseLeave={() => setShowDropdown1(false)}
                onMouseOver={() => setShowDropdown1(true)}
                show={showDropdown1}
                className="red"
                // style={{ color: "#ff0033 !important" }}
              >
                <NavLink
                  className="dropdown-item"
                  to="https://mail.zaeca.go.tz/"
                  target="new"
                >
                  STAFF MAIL
                </NavLink>
                <NavLink
                  className={"dropdown-item"}
                  to="https://zanvibali.goz.go.tz/"
                  target="new"
                >
                  ZANVIBALI
                </NavLink>
              </NavDropdown>
            </Nav>
            <Nav className=" nav-link">
              <Nav className="nav-link">
                {/* <BsTranslate /> */}
                Lang:
                <select
                  onChange={onChangeLanguage}
                  defaultValue={currentLocale}
                  className="for"
                >
                  {localeList.map((locale, index) => (
                    <option key={index} value={locale}>
                      {locale}
                    </option>
                  ))}
                </select>
              </Nav>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
