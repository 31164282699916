import React, { useState } from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import dg from "../../asset/DG-Ali-thumbnail-size.jpg";
import { News } from "./newsEvent";
import { useTranslation } from "react-i18next";
import { DGMessage } from "./dgMessage";

export const LeftSide = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <>
      <Card className="dgCard rounded-0">
        <Card.Body style={{ textAlign: "center" }}>
          <img
            src={dg}
            width={"180"}
            alt="..."
            class="rounded-circle circle"
            style={{ marginBottom: "10px" }}
          />
          <div>
            {/* <p style={{ textAlign: "center" }}>ALI ABDALLA ALI</p> */}
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              ALI ABDALLA ALI
              <br />
              {t("DG.DG")}
            </p>
          </div>
          <Button
            className="btn-secondary rounded-0"
            onClick={() => setShow(true)}
          >
            {t("DG.DGMessage")}
          </Button>
        </Card.Body>
      </Card>

      <DGMessage show={show} setShow={setShow} />
      {/* <Card className="rounded-0">
        <img src={pic} width={"100%"} />
      </Card> */}
    </>
  );
};
