import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Spinner, Row, ListGroup, Card } from "react-bootstrap";
import { listRedio } from "../../action/Redio";
import { DateFormat } from "../utility/dateFormat";
import { Pagination } from "../utility/pagination";
import { baseURL } from "../../action/baseUrl";
// import { listRedio } from "../../action/Redio";

export const Redio = () => {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState(undefined);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(1);
  // const { auth } = useContext(AuthContext);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + limit);
  };

  // const headers = {
  //   headers: {
  //     "Content-`Type": "application/json",
  //     Authorization: "Bearer" + auth?.access_token,
  //   },
  // };

  useEffect(() => {
    setTimeout(() => {
      listRedio(offset)
        .then((resp) => {
          setData(resp.data.data);
          console.log("dataaa");
          console.log(resp.status);
          console.log(resp.data.data);
        })
        .catch((error) => {
          console.log(error.toJSON().message);
          setMsgError(error.toJSON().message);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 10);
  }, [index, limit, offset]);

  return (
    <div className="space">
      <h2 className="red">Redio Programs</h2>
      <Row>
        {msgError ? (
          <div>
            <div>
              <Button variant="info rounded-0 form-control btn-lg" disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
                <br />
                Loading ...
              </Button>
            </div>
          </div>
        ) : (
          data.data?.map((dt, i) => (
            <Col lg={4} md={4} sm={12} xs={12} key={i}>
              <Card>
                <Card.Body>
                  <center>
                    <audio
                      src={`${baseURL}/storage/${dt.audio}`}
                      controls
                    ></audio>
                  </center>
                  <p>
                    {dt.title}
                    <span
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        float: "right",
                        fontSize: "small",
                      }}
                    >
                      Recorded at: <DateFormat date={dt.date} />
                    </span>
                  </p>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
      {/* {dt.body.substring(0, 150)}{" "}
          {dt.body.length > 150 && <a href="#">... read more</a>}{" "} */}

      <div className="container" style={{ display: "flex" }}>
        <Pagination
          dataLength={data.total}
          pageCount={data.last_page}
          handlePageClick={handlePageClick}
        />
      </div>
    </div>
  );
};
