import React, { useContext } from "react";
import { NavDropdown, Navbar, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import AuthContext from "../contex/AuthContext";
import Swal from "sweetalert2";

export const TopNav = () => {
  const { auth, logoutUser } = useContext(AuthContext);

  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        logoutUser();
        Swal.fire({
          title: "Logout!",
          text: "Your are logout successfully.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return (
    <Navbar expand="lg" className="bg-dark">
      <div className="container-fluid">
        <Navbar.Brand href="#">ZAECA</Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ backgroundColor: "white" }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink className="nav-link" to="/admin/news">
              News & Event
            </NavLink>
            <NavLink className="nav-link" to="/admin/complain">
              Complains
            </NavLink>
            <NavLink className="nav-link" to="/admin/barnner">
              Barnner
            </NavLink>
            <NavLink className="nav-link" to="/admin/gallery">
              Gallery
            </NavLink>
            <NavLink className="nav-link" to="/admin/redio/program">
              Redio Program
            </NavLink>
          </Nav>
          <Nav className="me">
            <NavDropdown title={auth.user.email} id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => logout()}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};
