import React, { useState, useRef, useContext } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addNews } from "../../action/News";
import JoditEditor from "jodit-react";
import AuthContext from "../../contex/AuthContext";
import Swal from "sweetalert2";

export const UpdateNews = (props) => {
  const { showUpdate, setShowUpdate, index, setIndex, updateData, title } =
    props;
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const { auth } = useContext(AuthContext);

  const handleClose = () => {
    setShowUpdate(false);
    formik.resetForm();
    setContent("");
  };

  const headers = {
    headers: {
      "Content-`Type": "application/json",
      Authorization: "Bearer" + auth?.access_token,
    },
  };
  const initialValues = {
    title: title,
    // detail: content,
    user_id: 1,
    // body
  };
  const productSchema = Yup.object().shape({
    title: Yup.string().min(3).required("Complain required"),
    detail: Yup.string(),
  });

  // const handleShow = () => setShow(true);
  const formik = useFormik({
    initialValues,
    validationSchema: productSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // setLoading(true)
      const { title, user_id } = values;
      const data = { title, user_id };
      data["detail"] = content;

      setTimeout(() => {
        addNews(data, headers)
          .then((resp) => {
            setIndex(index + 1);

            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              icon: "success",
              title: "News created successfully",
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            // console.log(data)
            // setLoading(false)
            setContent("");
            setSubmitting(false);
            resetForm();
            setShowUpdate(false);
          });
      }, 10);
    },
  });

  return (
    <>
      <Modal
        size="lg"
        show={showUpdate}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        centered
      >
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Update News & Event Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Title : {title}</Form.Label>
              <Form.Control
                type="tetx"
                placeholder="Title"
                autoFocus
                value={"xxxxxxxxxxxxxx"}
                {...formik.getFieldProps("title")}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="mt-2 fst-italic fw-normal h6">
                  <span className="text-danger">{formik.errors.title}</span>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Detail</Form.Label>
              <JoditEditor
                ref={editor}
                value={content}
                // config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={
                formik.isSubmitting || !formik.isValid || content.length < 3
              }
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
