import React, { useState, useContext, useEffect } from "react";
import {
  Tab,
  Tabs,
  Card,
  Table,
  Row,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import { BsCloudDownloadFill } from "react-icons/bs";
import { publicNewsAll } from "../../action/News";
import AuthContext from "../../contex/AuthContext";
import pic1 from "../../asset/hot1.png";
import { DateFormat } from "../utility/dateFormat";
import parse from "html-react-parser";
import newsPic from "../../asset/ZAECA_news2.jpeg";
import { Pagination } from "../utility";
import { ReadNews } from "./readNews";
import { baseURL } from "../../action/baseUrl";

export const AllNews = () => {
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(1);
  const [read, setRead] = useState(0);
  const [notRead, setNotRead] = useState(0);
  const [newsTitle, setNewsTitle] = useState("");
  const [newsBody, setNewsBody] = useState("");
  const [newsImage, setNewsImage] = useState("");
  const [newsFile, setNewsFile] = useState("");
  const [newsDate, setNewsDate] = useState("");
  const [msgError, setMsgError] = useState(null);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + limit);
  };

  const dataaa = () => {
    publicNewsAll(offset)
      .then((resp) => {
        setData(resp.data.data);
        console.log("dataaa");
        console.log(resp.status);
        console.log(resp.data.data);
      })
      .catch((error) => {
        // setError(error);
        // setLoading(false);
        console.log(error.toJSON().message);
        setMsgError(error.toJSON().message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReadNews = (data) => {
    console.warn(data);
    setRead(true);
    setNewsDate(data.created_at);
    setNewsBody(data.detail);
    setNewsTitle(data.title);
    setNewsImage(data.image);
    setNewsFile(data.file);
  };

  useEffect(() => {
    setTimeout(() => {
      dataaa();
    }, 10);
  }, [index, limit, offset]);
  return (
    <>
      <Card>
        <Card.Header as="h5">Featured</Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Name</th>
                <th style={{ textAlign: "center" }}>Date</th>
              </tr>
            </thead>
            <tbody>
              {msgError ? (
                <tr>
                  <td colSpan={4}>
                    <Button
                      variant="info rounded-0 form-control btn-lg"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                      />
                      <br />
                      Loading News...
                    </Button>
                  </td>
                </tr>
              ) : (
                data.data?.map((dt, i) => (
                  <tr>
                    <td>{1 + i++}</td>
                    <td>
                      {dt.image === null ? (
                        <img src={newsPic} width={"40px"} />
                      ) : (
                        <img
                          src={`${baseURL}/storage/${dt.image}`}
                          width={"40px"}
                        />
                      )}
                    </td>
                    <td>
                      {dt.title}{" "}
                      <a href="#" onClick={() => handleReadNews(dt)}>
                        Read news
                      </a>
                    </td>
                    <td>
                      <DateFormat date={dt.created_at} />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <Pagination
            dataLength={data.total}
            pageCount={data.last_page}
            handlePageClick={handlePageClick}
          />
          <ReadNews
            read={read}
            setRead={setRead}
            newsBody={newsBody}
            newsTitle={newsTitle}
            newsDate={newsDate}
            newsImage={newsImage}
            newsFile={newsFile}
          />
        </Card.Body>
      </Card>
    </>
  );
};
