import React from "react";
import { Accordion, Container, Col, Row, Table, Card } from "react-bootstrap";
import { BsGeoAlt } from "react-icons/bs";
import { useTranslation } from "react-i18next";
// import Table from "react-bootstrap";

export const Home = () => {
  const [t] = useTranslation();
  return (
    <Container className="space">
      <h3>Free Toll: 113</h3>
      <h3>Email: info@zaeca.go.tz</h3>
      <Card>
        <Card.Header>Contact List</Card.Header>
        <Card.Body>
          <div class="table-responsive">
            <Table className="table">
              <thead>
                <tr>
                  <th>{t("contact.office")}</th>
                  <th>{t("contact.phone")}</th>
                  <th>{t("contact.address")}</th>
                  <th>{t("contact.box")}</th>
                  <th>FAX</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("contact.HQ")}</td>
                  <td>+255 774 824 242</td>
                  <td>Kilimani Mnara wa mbao-Zanzibar, Tanzania</td>
                  <td>{t("contact.box")} 3650</td>
                  <td>+255242231461</td>
                </tr>
                <tr>
                  <td>{t("contact.UW")}</td>
                  <td>+255 777 824 242</td>
                  <td>Kilimani Mnara wa mbao-Zanzibar, Tanzania</td>
                  <td>{t("contact.box")} 3650</td>
                  <td>+255242231461</td>
                </tr>
                <tr>
                  <td>{t("contact.SP")}</td>
                  <td>+255 773 824 242</td>
                  <td>Wawi Makonyo Pemba-Zanzibar, Tanzania</td>
                  <td>{t("contact.box")} 3650</td>
                  <td>+255242231461</td>
                </tr>
                <tr>
                  <td>{t("contact.NP")}</td>
                  <td>+255 772 824 242</td>
                  <td>Kinyasini Pemba-Zanzibar, Tanzania</td>
                  <td>{t("contact.box")} 3650</td>
                  <td>+255242231461</td>
                </tr>
                <tr>
                  <td>{t("contact.NU")}</td>
                  <td>+255 776 824 242</td>
                  <td>Gamba Unguja-Zanzibar, Tanzania</td>
                  <td>{t("contact.box")} 3650</td>
                  <td>+255242231461</td>
                </tr>
                <tr>
                  <td>{t("contact.SU")}</td>
                  <td>+255 779 824 242</td>
                  <td>Tunguu Unguja-Zanzibar, Tanzania</td>
                  <td>{t("contact.box")} 3650</td>
                  <td>+255242231461</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};
