import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import radio from "../../asset/hot1.png";
import freeToll from "../../asset/Sequence-01_1.gif";
import { Link } from "react-router-dom";

export const AwarenessComp = () => {
  return (
    <div>
      <Row>
        <Col lg={9} md={9} sm={12}>
          <Card
            className="rounded-0"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            <Row>
              <Col lg={3} md={3} sm={12}>
                <Card className="rounded-0 border-0">
                  <img src={radio} alt="" srcset="" />
                </Card>
              </Col>
              <Col lg={9} md={9} sm={12}>
                <Card className="rounded-0 border-0">
                  <h3>TV and Radio Programs</h3>
                  <p>
                    Awareness Unit has made great efforts to achieve the main
                    goal of educating the public about corruption and economic
                    crimes and they are effects for the purpose of involving
                    public to prevent corruption and economic crimes and
                    changing of people's attitude towards corruption.
                  </p>
                  <Link
                    to={"/awareness/redio/program"}
                    className="btn btn-warning"
                    style={{ width: "150px" }}
                  >
                    Radio programs
                  </Link>
                </Card>
              </Col>
            </Row>
          </Card>
          <Card
            className="rounded-0"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            <Row>
              <Col lg={3} md={3} sm={12}>
                <Card className="rounded-0 border-0">
                  <img src={radio} alt="" srcset="" />
                </Card>
              </Col>
              <Col lg={9} md={9} sm={12}>
                <Card className="rounded-0 border-0">
                  <h3>Community Education</h3>
                  <p>
                    Awareness Unit has made great efforts to achieve the main
                    goal of educating the public about corruption and economic
                    crimes and they are effects for the purpose of involving
                    public to prevent corruption and economic crimes and
                    changing of people's attitude towards corruption.
                  </p>
                  <Button
                    className="btn btn-primary"
                    style={{ width: "150px" }}
                  >
                    Go somewhere
                  </Button>
                </Card>
              </Col>
            </Row>
          </Card>
          <Card
            className="rounded-0"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            <Row>
              <Col lg={3} md={3} sm={12}>
                <Card className="rounded-0 border-0">
                  <img src={radio} alt="" srcset="" />
                </Card>
              </Col>
              <Col lg={9} md={9} sm={12}>
                <Card className="rounded-0 border-0">
                  <h3>Seminar Schedule</h3>
                  <p>
                    Awareness Unit has made great efforts to achieve the main
                    goal of educating the public about corruption and economic
                    crimes and they are effects for the purpose of involving
                    public to prevent corruption and economic crimes and
                    changing of people's attitude towards corruption.
                  </p>
                  <Button
                    className="btn btn-primary"
                    style={{ width: "150px" }}
                  >
                    Go somewhere
                  </Button>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={3} md={3} sm={12}>
          <br />
          <Button className="rounded-0 btn-info">
            Toa taarifa kuhusiana na vitendo vya Runshwa na uhujumu uchumi
          </Button>
          <Card>
            <img src={freeToll} alt="" srcset="" />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
