import React, { useEffect, useState, useContext } from "react";
import { DateFormat } from "../../component/utility/dateFormat";
import { Pagination } from "../../component/utility";
import { AddNews } from "./addNews";
import { UpdateNews } from "./updateNews";
import { isRead } from "../../action/Complain";
import { Button, ButtonGroup } from "react-bootstrap";
import { ReadNews } from "../../component/home/readNews";
import {
  BsPlusSquareFill,
  BsToggleOn,
  BsToggleOff,
  BsStarFill,
  BsStar,
} from "react-icons/bs";
import parse from "html-react-parser";
import { deleteNews, publishNews, hotNews } from "../../action/News";
import AuthContext from "../../contex/AuthContext";
import Swal from "sweetalert2";

export const NewsTable = (props) => {
  const { setOffset, offset, data, index, setIndex } = props;
  const [limit, setLimit] = useState(1);
  const [show, setShow] = useState(false);
  const [viewData, setViewData] = useState([]);
  const { auth } = useContext(AuthContext);
  const [msg, setMsg] = useState();
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [title, setTitle] = useState("");
  const [newsBody, setNewsBody] = useState("");
  const [newsImage, setNewsImage] = useState("");
  const [newsFile, setNewsFile] = useState("");
  const [newsDate, setNewsDate] = useState("");
  const [read, setRead] = useState(false);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + limit);
  };

  const headers = {
    headers: {
      "Content-`Type": "application/json",
      Authorization: "Bearer" + auth?.access_token,
    },
  };

  // useEffect(() => {

  // })

  const handleShow = () => {
    setShow(true);
  };

  const handleUpdate = (dt) => {
    setUpdateData(dt);
    console.log(dt);
    setTitle(dt.title);
    setShowUpdate(true);
  };
  const handleView = (dt) => {
    setNewsDate(dt.created_at);
    setTitle(dt.title);
    setNewsBody(dt.detail);
    setNewsImage(dt.image);
    setNewsFile(dt.file);
    setRead(true);
  };

  const handlePublish = (data) => {
    const pub =
      data.isPublished === 1
        ? "drop this news from the public"
        : "publish this news to the public";
    const pubPast = data.isPublished === 1 ? "Drop" : "Published";
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${pub} !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${pubPast} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        publishNews(data.id, data, headers).then((resp) => {
          setIndex(index + 1);
        });
        Swal.fire({
          title: `${pubPast} !`,
          text: `News has ${pubPast} successfully.`,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });

    console.log(data);
  };

  const handleHot = (data) => {
    const pub =
      data.isHot === 1 ? "remove from hot news" : "make this news to be hot";
    const pubPast = data.isHot === 1 ? "Remove" : "Make Hot";
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${pub} !`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${pubPast} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        hotNews(data.id, data, headers).then((resp) => {
          setIndex(index + 1);
        });
        Swal.fire({
          title: `${pubPast} !`,
          text: `News has ${pubPast} successfully.`,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });

    console.log(data);
  };

  const handleDelete = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You wont to delete news !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNews(data.id, headers).then((resp) => {});
        setIndex(index + 1);
        Swal.fire({
          title: "Deleted!",
          text: "News has been deleted.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  return (
    <>
      <div className="card mb-4">
        <div className="card-header">
          <span>
            News & complains{" "}
            <button
              style={{ float: "right" }}
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleShow();
              }}
            >
              <BsPlusSquareFill /> New
            </button>
          </span>
        </div>
        <div className="card-body table">
          <table
            className="table table-responsive table-striped table-hover"
            id="datatablesSimple"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                {/* <th>Detail</th> */}
                <th style={{ whiteSpace: "nowrap" }}>Is Published</th>
                <th style={{ whiteSpace: "nowrap" }}>Is Hot</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.data?.map((dt, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{dt.title}</td>
                  {/* <td>{parse(dt.detail)}</td> */}
                  <td>
                    <center onClick={() => handlePublish(dt)}>
                      {dt.isPublished === 1 ? (
                        <BsToggleOn className="text-danger" />
                      ) : (
                        <BsToggleOff className="text-secondary" />
                      )}
                    </center>
                  </td>
                  <td>
                    <center onClick={() => handleHot(dt)}>
                      {dt.isHot === 1 ? (
                        <BsStarFill className="text-danger" />
                      ) : (
                        <BsStar className="text-danger" />
                      )}
                    </center>
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <DateFormat date={dt.created_at} />
                  </td>
                  <td>
                    <ButtonGroup size="xs">
                      <Button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleView(dt)}
                      >
                        View
                      </Button>
                      <Button
                        className="btn btn-warning btn-sm"
                        onClick={() => handleUpdate(dt)}
                      >
                        Edit
                      </Button>
                      <Button
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          handleDelete(dt);
                        }}
                      >
                        Trash
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          dataLength={data.total}
          pageCount={data.last_page}
          handlePageClick={handlePageClick}
        />
      </div>
      <AddNews
        show={show}
        setShow={setShow}
        // viewData={viewData}
        index={index}
        setIndex={setIndex}
      />
      <UpdateNews
        showUpdate={showUpdate}
        setShowUpdate={setShowUpdate}
        updateData={updateData}
        index={index}
        setIndex={setIndex}
        title={title}
      />
      <ReadNews
        read={read}
        setRead={setRead}
        newsBody={newsBody}
        newsTitle={title}
        newsDate={newsDate}
        newsImage={newsImage}
        newsFile={newsFile}
      />
    </>
  );
};
