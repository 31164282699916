import { useState } from "react";
import { Tab, Tabs, Card, Table } from "react-bootstrap";
import { BsCloudDownloadFill, BsFileEarmarkPdf } from "react-icons/bs";
import zaeca_5_2023_sw from "../../asset/resources/act_5_2023_sw.pdf";
import zaeca_5_2023_eng from "../../asset/resources/act_5_2023_eng.pdf";
import zaeca_1_2012_eng from "../../asset/resources/zaeca_2012_eng.pdf";
import Zanzibar_constitutional_eng from "../../asset/resources/Zanzibar_constitutional.pdf";
import katibaZanzibar1984 from "../../asset/resources/Katiba-ya-Zanzibar-ya-1984-Toleo-la-2020.pdf";
import TanzaniaConstitution from "../../asset/resources/UnitedRepuplicOfTanzaniaConstitution.pdf";
import IAACA from "../../asset/resources/Constitution of IAACA (2022)_rev2.pdf";
import NACSAP_I from "../../asset/resources/Awamu-ya-Kwanza-NACSAP-I.pdf";
import NACSAP_II from "../../asset/resources/Mkakati-wa-pili-NACSAP-II.pdf";
import NACSAP_III from "../../asset/resources/NACSAP_III_SWAHILI2.pdf";
import NACSAP_IV from "../../asset/resources/NACSAP-IV-ENGLISH.pdf";
import ZADEP from "../../asset/resources/zadep.pdf";
import DEVELOPMENT_VISION from "../../asset/resources/DEVELOPMENT _VISION.pdf";
import ANTI_CORRUPTION_STRATEGY from "../../asset/resources/NATIONAL ANTI CORRUPTION STRATEGY.pdf";
import UN_Convention_Against_Corruption from "../../asset/resources/UN_Convention_Against_Corruption.pdf";
import { useTranslation } from "react-i18next";

export const ResourceTab = () => {
  const [key, setKey] = useState("home");
  const [t] = useTranslation();

  return (
    <Card>
      <Card.Header as="h4">{t("menu.Resources")}</Card.Header>
      <Card.Body>
        <Tabs
          defaultActiveKey={t("Resource.law")}
          transition={true}
          id="noanim-tab-example"
          className="mb-3 text-secondary"
        >
          <Tab
            eventKey={t("Resource.law")}
            className="text-secondary"
            title={t("Resource.law")}
          >
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th style={{ textAlign: "center" }}>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>3</td>
                  <td>Katiba ya Zanzibar ya 1984 Toleo la 2020</td>
                  <td>
                    <a href={katibaZanzibar1984} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    Zanzibar Anti-Corruption and Economic Crimes Acts, No. 5 of
                    2023
                  </td>
                  <td>
                    <a href={zaeca_5_2023_eng} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                    <br />
                    <a href={zaeca_5_2023_sw} target="new">
                      <BsFileEarmarkPdf /> Swahili version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    Zanzibar Anti-Corruption and Economic Crimes Acts, No. 1 of
                    2012
                  </td>
                  <td>
                    <a href={zaeca_1_2012_eng} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>The Zanzibar Constitution</td>
                  <td>
                    <a href={Zanzibar_constitutional_eng} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>The United Republic of Tanzania Constitution</td>
                  <td>
                    <a href={TanzaniaConstitution} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    Constitution of the International Association of
                    Anti-Corruption Authorities (IAACA) Rev.2 2022
                  </td>
                  <td>
                    <a href={IAACA} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab
            eventKey={t("Resource.strategies")}
            title={t("Resource.strategies")}
          >
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th style={{ textAlign: "center" }}>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    THE NATIONAL ANTI-CORRUPTION STRATEGY AND ACTION PLAN - I
                    (NACSAP I)
                  </td>
                  <td>
                    <a href={NACSAP_I} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    THE NATIONAL ANTI-CORRUPTION STRATEGY AND ACTION PLAN - II
                    (NACSAP II)
                  </td>
                  <td>
                    <a href={NACSAP_II} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    THE NATIONAL ANTI-CORRUPTION STRATEGY AND ACTION PLAN - III
                    (NACSAP III)
                  </td>
                  <td>
                    <a href={NACSAP_III} target="new">
                      <BsFileEarmarkPdf /> Swahili version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    THE NATIONAL ANTI-CORRUPTION STRATEGY AND ACTION PLAN - IV
                    (NACSAP IV)
                  </td>
                  <td>
                    <a href={NACSAP_IV} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    ZANZIBAR STRATEGY FOR ANTI-CORRUPTION AND ECONOMIC CRIMES
                    (2017-2022)
                  </td>
                  <td>
                    <a href={ANTI_CORRUPTION_STRATEGY} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>UN Convention Against Corruption</td>
                  <td>
                    <a href={UN_Convention_Against_Corruption} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab
            eventKey={t("Resource.publication")}
            title={t("Resource.publication")}
          >
            Tab content for Majarida
          </Tab>
          <Tab eventKey={t("Resource.other")} title={t("Resource.other")}>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th style={{ textAlign: "center" }}>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>4</td>
                  <td>
                    MONITORING & EVALUATION FRAMEWORK FOR THE ZANZIBAR
                    DEVELOPMENT PLAN (ZADEP)
                  </td>
                  <td>
                    <a href={ZADEP} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Zanzibar Development Vision 2050</td>
                  <td>
                    <a href={DEVELOPMENT_VISION} target="new">
                      <BsFileEarmarkPdf /> English version
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
};
