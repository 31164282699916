import React from "react";
import { Tabs, Tab, Container, Card } from "react-bootstrap";

export const MissionVission = () => {
  return (
    <Container className="space">
      {/* <h3>MISSION & VISSION</h3> */}
      <Card>
        <Card.Header as="h4">MISSION & VISSION</Card.Header>
        <Card.Body>
          <Tabs
            defaultActiveKey="Mission"
            transition={true}
            id="noanim-tab-example"
            className="mb-3 text-secondary"
          >
            <Tab eventKey="Mission" className="text-secondary" title="Mission">
              <p>
                To enable the achievement of viable economic prosperity and free
                corruption environment in Zanzibar through prevention, public
                education, investigation and recovery of public assets.
              </p>
            </Tab>
            <Tab eventKey="Vission" className="text-secondary" title="Vission">
              <p>
                Viable economic prosperity in ethical and free corruption
                environment.
              </p>
            </Tab>
            <Tab
              eventKey="Code of Ethics"
              className="text-secondary"
              title="Code of Ethics"
            >
              <p>
                Officers of the ZAECA will at all times uphold the good name of
                the Authority and:
              </p>
              <ol class="list-group list-group-numbered">
                <li class="list-group-item">
                  Adhere to the principles of integrity and fair play
                </li>
                <li class="list-group-item">
                  Respect the rights under the law of all people
                </li>
                <li class="list-group-item">
                  Carry out their duties without fear or favour, prejudice or
                  ill will
                </li>
                <li class="list-group-item">
                  Act always in accordance with the law
                </li>
                <li class="list-group-item">
                  Not take advantage of their authority or position
                </li>
                <li class="list-group-item">
                  Maintain necessary confidentiality
                </li>
                <li class="list-group-item">
                  Accept responsibility for their actions and instructions
                </li>
                <li class="list-group-item">
                  Exercise courtesy and restraint in word and action
                </li>
                <li class="list-group-item">
                  Strive for personal and professional excellence
                </li>
              </ol>
            </Tab>
            <Tab
              eventKey="Performance Standards"
              className="text-secondary"
              title="Performance Standards"
            >
              <p>
                We are committed to achieving the following performance
                standards:
              </p>
              <ol class="list-group list-group-numbered">
                <li class="list-group-item">
                  Respond to a report of corruption within 48 hours.
                </li>
                <li class="list-group-item">
                  Respond to a report which does not involve corruption within
                  two working days.
                </li>
                <li class="list-group-item">
                  Respond to a request for corruption prevention advice within
                  two working days.
                </li>
                <li class="list-group-item">
                  Respond to a request for anti-corruption education services or
                  information within two working days.
                </li>
              </ol>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </Container>
  );
};
